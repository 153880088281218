import React from 'react'
import { Link } from 'gatsby'

import * as styles from './navigation.module.css'

const Navigation = () => (
  <nav role="navigation" className={styles.container} aria-label="Main">
    <Link to="/blog" className={styles.logoLink}>
      <span className={styles.logo} />
      <span className={styles.navigationItem}>Home</span>
    </Link>
    <ul className={styles.navigation}>
{/*       
      <li className={styles.navigationItem}>
        <Link to="/" activeClassName="active">
          Home
        </Link>
      </li>
       */}
      <li className={styles.navigationItem}>
        <Link to="https://docs.google.com/forms/d/e/1FAIpQLScEktGjA_jIf9b2GOcDjzxCXsSj1LPS5jGYQTmzLD8VcUoQHw/viewform?usp=sf_link" activeClassName="active">
          お問い合わせ
        </Link>
      </li>
    </ul>
  </nav>
)

export default Navigation
